/**
 * 把对象默认转为 [{ key, value }] 形式的数组
 * @param {Object} obj 要转换的对象
 * @param {Function} middleware 中间件函数，用于对每个元素做中间处理
 * @returns Array
 */
export function objectToArray(obj, middleware) {
  const arr = [];
  for (const key in obj) {
    if (Object.hasOwnProperty.call(obj, key)) {
      const value = obj[key];
      let item = { key, value };
      if (middleware) {
        item = middleware(item);
      }
      arr.push(item);
    }
  }
  return arr;
}
