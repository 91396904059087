<template>
  <div id="json-editor">
    <span v-show="!value && showPlaceholder" class="placeholder">
      -- 请粘贴请求json内容，键入&#x3C;&#x3E;可快速选择视图列名 --
    </span>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import * as monaco from "monaco-editor";
import { LANG_ID, registerCompletionItemProvider } from "./language";

export default {
  props: {
    completionProviderList: {
      type: Array,
      default: () => []
    },
    value: String
  },
  watch: {
    completionProviderList: {
      immediate: true,
      handler: function() {
        if (this.completionProviderList) {
          registerCompletionItemProvider(this.completionProviderList);
        }
      }
    },
    value() {
      if (this.editor) {
        this.editor.setValue(this.value);
      }
    }
  },
  data() {
    return {
      showPlaceholder: true
    };
  },
  mounted() {
    this.init();
  },
  destroyed() {
    this.editor.dispose();
  },
  methods: {
    init() {
      const editor = monaco.editor.create(this.$el, {
        value: this.value,
        language: LANG_ID,
        lineNumbers: "on",
        renderLineHighlight: false,
        fontSize: 13,
        padding: {
          top: 8,
          bottom: 8
        },
        contextmenu: false,
        minimap: {
          enabled: false
        },
        scrollbar: {
          verticalScrollbarSize: 6,
          horizontalScrollbarSize: 6,
          useShadows: false
        }
      });
      editor.trigger(this.value, "editor.action.formatDocument");
      // 监听内容变化
      editor.onDidChangeModelContent(() => {
        //   this.$emit("input", editor.getValue());
        this.showPlaceholder = editor.getValue() ? false : true;
      });
      // 失去焦点
      editor.onDidBlurEditorText(() => {
        this.$emit("input", editor.getValue());
      });
      // resize 时重新 layout
      const callback = debounce(function() {
        editor.layout();
      }, 300);
      const observer = new ResizeObserver(callback);
      observer.observe(this.$el);
      this.editor = editor;
    }
  }
};
</script>

<style lang="less" scoped>
#json-editor {
  margin-bottom: 8px;
  min-height: 300px;
  max-height: 500px;
  position: relative;
  .placeholder {
    position: absolute;
    left: 65px;
    font-size: 13px;
    color: #aaa;
    top: 8px;
    z-index: 1;
    pointer-events: none;
  }
  /deep/ .overflow-guard {
    background-color: #f1fafe !important;
    border: 1px dashed #bae7ff;
    border-radius: 4px;
  }
}
</style>
