<template>
  <!-- 推送数据 -->
  <a-steps
    v-if="schedule.pushType === 'data'"
    progress-dots
    :current="value"
    @change="onChange"
  >
    <!-- 第一步 -->
    <a-step title="选择视图" :description="datasheet.nodeName">
      <Iconfont slot="icon" :type="datasheet.icon || 'series1/发现 91.svg'" />
    </a-step>
    <!-- 第二步 -->
    <a-step title="应用设置" :description="appName">
      <AppIcon slot="icon" :type="schedule.appType" />
    </a-step>
    <!-- 第三步 -->
    <a-step title="任务设置" :description="taskDesc">
      <a-icon slot="icon" type="setting" theme="twoTone" />
    </a-step>
  </a-steps>
  <!-- 推送消息 -->
  <a-steps v-else progress-dots :current="value" @change="onChange">
    <!-- 第一步 -->
    <a-step title="应用设置" :description="appName">
      <AppIcon slot="icon" :type="schedule.appType" />
    </a-step>
    <!-- 第二步 -->
    <a-step title="推送条件" description="满足条件时才推送消息">
      <Iconfont slot="icon" type="emoji/23f0.svg" />
    </a-step>
    <!-- 第三步 -->
    <a-step title="消息设置" description="推送内容">
      <a-icon slot="icon" type="setting" theme="twoTone" />
    </a-step>
  </a-steps>
</template>

<script>
import workspace from "@/views/datamodel/models/workspace";
import Iconfont from "@/components/iconfont";
import AppIcon from "../src/AppIcon";
import { getAppName } from "../util-applist";

export default {
  components: { Iconfont, AppIcon },
  props: {
    schedule: Object,
    value: Number
  },
  computed: {
    datasheet() {
      const datasheetId = this.schedule.datasheetId;
      return workspace.getNodeById(datasheetId) || { nodeName: "请选择" };
    },
    appName() {
      return getAppName(this.schedule.appType);
    },
    taskDesc() {
      const { appType } = this.schedule;
      return "同步频率、" + (appType === "http" ? "同步模式" : "字段映射");
    }
  },
  methods: {
    onChange(current) {
      const step = current - this.value;
      if (step > 0) {
        this.$emit("next");
      } else {
        this.$emit("prev", Math.abs(step));
      }
    }
  }
};
</script>

<style lang="less" scoped>
.ant-steps {
  width: 80%;
  max-width: 760px;
  margin: 0 auto;
}
</style>
