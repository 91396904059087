<template>
  <a-empty
    v-if="!datasheets.length"
    description="暂无数据"
    style="margin-top: 100px"
  />
  <div v-else>
    <div
      v-for="item in datasheets"
      :key="item.nodeId"
      :class="['list-item', { active: item.nodeId === datasheetId }]"
      @click="datasheetId = item.nodeId"
    >
      <Iconfont :type="item.icon" />
      <span class="label">{{ item.nodeName }}</span>
    </div>
  </div>
</template>

<script>
import workspace from "@/views/datamodel/models/workspace";
import Iconfont from "@/components/iconfont";

export default {
  components: { Iconfont },
  props: {
    schedule: Object
  },
  data() {
    // eslint-disable-next-line prettier/prettier
    const datasheetId = this.schedule.datasheetId || workspace.currentDatasheetId;
    return {
      datasheetId
    };
  },
  computed: {
    datasheets() {
      const children = workspace.currentWorkspace.children || [];
      return children.filter(item => item.isDatasheet);
    }
  },
  created() {
    this.validate();
  },
  watch: {
    datasheetId() {
      this.validate();
    }
  },
  methods: {
    next() {
      this.schedule.datasheetId = this.datasheetId;
    },
    validate() {
      this.$emit("validate", !!this.datasheetId);
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "~@/assets/app.less";

.list-item {
  max-width: 380px;
  margin: 1.2em auto;
  display: flex;
  align-items: center;
  padding: 10px 14px;
  background: #f5f5f5;
  border-radius: 8px;
  cursor: pointer;
  border: solid 1px transparent;

  .icon-symbol {
    width: 1.3em;
    height: 1.3em;
  }

  .label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-left: 12px;
  }

  &.active {
    border: solid 1px @blue-6;
    background: @blue-1;
  }
  &:first-child {
    margin-top: 100px;
  }
}
</style>
