<template>
  <div>
    <div class="toolbar">
      <Focusable @click="$emit('back')">
        <i class="ndl-icon-arrow-left"></i>
      </Focusable>
      <span class="toolbar-title">
        {{ schedule.name }}（{{ jobs.length }}）
      </span>
      <a-tooltip v-if="schedule.pushType === 'data'" title="重置">
        <Focusable @click="onReset()">
          <a-icon type="stop" />
        </Focusable>
      </a-tooltip>
    </div>
    <a-skeleton v-if="loading" active />
    <a-empty v-else-if="!jobs.length" style="margin-top: 80px" />
    <Record
      v-for="(item, index) in jobs"
      :key="index"
      :record="item"
      :show-description="schedule.pushType === 'data'"
    />
  </div>
</template>

<script>
import * as API from "@/api/datasheet";
import polling from "@/utils/polling";
import scheduleModel from "@/views/datamodel/models/schedule";
import Focusable from "@/views/datamodel/components/focusable";
import Record from "./HistoryRecord";

export default {
  components: { Focusable, Record },
  props: {
    schedule: Object
  },
  data() {
    return {
      jobs: [],
      loading: true
    };
  },
  created() {
    this.polling = polling(this.getJobs);
  },
  destroyed() {
    this.polling.abort();
  },
  methods: {
    async getJobs() {
      const { data } = await API.getScheduleHistory({ id: this.schedule.id });
      this.jobs = data;
      this.loading = false;
    },
    onReset() {
      this.$confirm({
        title: "操作确认",
        content: `确定要重置此任务吗？`,
        okType: "danger",
        onOk: async () => {
          await scheduleModel.reset(this.schedule.id);
          return this.polling.start();
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.toolbar {
  display: flex;
  align-items: center;
  margin: 8px 0;
  .focusable {
    flex-shrink: 0;
  }
  &-title {
    flex: 1 1 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 8px;
  }
}
</style>
