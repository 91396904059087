<template>
  <div v-if="loading" class="editor-wrapper">
    <a-skeleton active />
  </div>
  <JSONSchema
    v-else
    v-model="schedule.appConfig"
    ref="jsonSchema"
    class="editor-wrapper ndl-antd"
    :specification="specification"
  >
    <!-- 欺骗浏览器自动填充 -->
    <div
      slot="header"
      style="height: 0; overflow: hidden; position: fixed; left: -999px; top: -999px;"
    >
      <input type="text" />
      <input type="password" />
    </div>
    <AppHistory
      v-if="!schedule.id"
      slot="header"
      style="order: -2"
      :app-type="schedule.appType"
      @select="schedule.appConfig = $event"
    />
    <a-form-item
      slot="header"
      label="推送任务名称"
      required
      style="order: -1"
      :validate-status="scheduleNameValidateStatus"
    >
      <a-input
        v-model="schedule.name"
        @change="validateName($event.target.value)"
      />
    </a-form-item>
  </JSONSchema>
</template>

<script>
import JSONSchema from "@/components/json-schema-ui";
import AppHistory from "./StepsAppHistory";

export default {
  components: { JSONSchema, AppHistory },
  props: {
    /**
     * @type {import("../model").default}
     */
    schedule: Object
  },
  data() {
    return {
      loading: true,
      specification: {},
      scheduleNameValidateStatus: "success"
    };
  },
  created() {
    this.getSpecification();
    this.$emit("validate", !!this.schedule.name);
  },
  methods: {
    validateName(value) {
      this.scheduleNameValidateStatus = value ? "success" : "error";
      this.$emit("validate", !!value);
      return !!value;
    },
    async getSpecification() {
      this.loading = true;
      try {
        /**
         * @type {{ connectionSpecification: object }}
         */
        const spec = await this.schedule.getAppSpecification();
        this.specification = spec.connectionSpecification;
      } finally {
        this.loading = false;
      }
    },
    validate() {
      const isNameValid = this.validateName(this.schedule.name);
      const jsonSchemaValid = this.$refs.jsonSchema.validate();
      // 校验失败滚动到第一个失败表单项
      // 校验通过 errorField 基本上为 null，因此不做多余判断
      this.$nextTick(() => {
        const errorField = document.querySelector(".has-error");
        if (errorField) {
          errorField.scrollIntoView({ behavior: "smooth" });
        }
      });
      return isNameValid && jsonSchemaValid;
    },
    next() {
      if (this.validate()) {
        return Promise.resolve(this.schedule);
      } else {
        return Promise.reject(this.schedule);
      }
    }
  }
};
</script>

<style lang="less" scoped>
.editor-wrapper {
  max-width: 680px;
  margin: 36px auto;
  padding: 0 1.3em;
  display: flex;
  flex-direction: column;
  /deep/ .ant-form-item-with-help {
    margin-bottom: 48px;
  }
  /deep/ label,
  /deep/ .custom-label {
    color: rgb(35, 45, 84);
  }
  /deep/ .ant-form-item,
  /deep/ .footer {
    order: 10000;
  }
}
</style>
