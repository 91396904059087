import upperFirst from "lodash/upperFirst";
import { objectToArray } from "./utils";

export const Method = {
  GET: "get",
  POST: "post",
  PUT: "put",
  PATCH: "patch",
  DELETE: "delete"
};

export const MethodOptions = objectToArray(Method);

export const AuthType = {
  NONE: "none",
  BASIC_AUTH: "basicauth",
  BEARER_TOKEN: "bearertoken",
  API_KEY: "apikey",
  OAUTH2: "oauth2"
};

export const AuthTypeOptions = objectToArray(AuthType, item => {
  item.key = item.key
    .toLocaleLowerCase()
    .split("_")
    .map(word => upperFirst(word))
    .join("");
  return item;
});

export const AppendPosition = {
  HEADER: "header",
  QUERY: "query"
};

export const AppendPositionOptions = [
  { key: "Header", value: AppendPosition.HEADER },
  { key: "Query", value: AppendPosition.QUERY }
];

export const Oauth2Type = {
  PERMANENT: "permanent",
  TEMPORARY: "temporary"
};

export const Oauth2TypeOptions = [
  { key: "永久", value: Oauth2Type.PERMANENT },
  { key: "临时", value: Oauth2Type.TEMPORARY }
];
