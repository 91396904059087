import Observer from "@/utils/observer";

export default class Schedule extends Observer {
  constructor(opts) {
    super();
    opts = opts || {};
    // 任务 ID
    this.id = opts.id || null;
    // 任务名称
    this.name = opts.name || "";
    // 视图 ID
    this.datasheetId = opts.datasheetId || "";
    // 推送方式，数据（data）或消息（message）
    this.pushType = "data";
    // 应用类型
    this.appType = opts.appType || "";
    // 应用配置
    this.appConfig = opts.appConfig || {};
    // 推送频率
    this.schedule = opts.schedule || null;
  }
}
