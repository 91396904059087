<template>
  <div class="wrapper ndl-antd">
    <!-- 欺骗浏览器自动填充 -->
    <div
      slot="header"
      style="height: 0; overflow: hidden; position: fixed; left: -999px; top: -999px;"
    >
      <input type="text" />
      <input type="password" />
    </div>
    <!-- 历史配置 -->
    <AppHistory
      v-if="!schedule.id"
      slot="header"
      :app-type="schedule.appType"
      @select="schedule.appConfig = $event"
    />
    <!-- 表单 -->
    <a-form layout="vertical">
      <a-form-item
        label="推送任务名称"
        required
        :validate-status="scheduleNameValidateStatus"
      >
        <a-input v-model="schedule.name" />
      </a-form-item>

      <RequestAddress
        label="请求地址"
        required
        :config="appConfig"
        :validate-status="scheduleConfigValidateStatus"
      />

      <RequestParams label="请求 Header" :params="appConfig.header" />

      <RequestParams label="请求 Query" :params="appConfig.query" />

      <RequestAuth label="认证信息" v-model="appConfig.auth" />

      <RequestBody
        label="请求 Body"
        v-model="appConfig.raw"
        :completion-provider-list="completionProviderList"
      />
    </a-form>
  </div>
</template>

<script>
import AppHistory from "../type-data/StepsAppHistory";
import RequestAddress from "./RequestAddress";
import RequestParams from "./RequestParams";
import RequestAuth from "./RequestAuth";
import RequestBody from "./RequestBody";
import HttpAppConfig from "./models/http-app-config";

export default {
  components: {
    AppHistory,
    RequestAddress,
    RequestParams,
    RequestAuth,
    RequestBody
  },
  props: {
    /**
     * @type {import("../model").default}
     */
    schedule: Object
  },
  data() {
    return {
      scheduleNameValidateStatus: "success",
      scheduleConfigValidateStatus: "success",
      completionProviderList: []
    };
  },
  watch: {
    "schedule.name"() {
      if (this.schedule.name) {
        this.validate();
      }
    },
    "appConfig.method"() {
      if (this.appConfig.method) {
        this.validate();
      }
    },
    "appConfig.url"() {
      if (this.appConfig.url) {
        this.validate(true);
      }
    }
  },
  computed: {
    appConfig() {
      return this.schedule.appConfig;
    }
  },
  async created() {
    this.schedule.appConfig = new HttpAppConfig(this.schedule.appConfig || {});
    this.validate();
    const columnsList = await this.schedule.getDatasheetFields();
    this.completionProviderList = columnsList.map(item => item.columnName);
  },
  methods: {
    validate(showStatus = false) {
      const { validate } = HttpAppConfig;
      const isValidName = this.schedule.name;
      const isValidConfig = validate(this.appConfig);
      const isValid = isValidName && isValidConfig;
      this.$emit("validate", isValid);

      if (showStatus) {
        this.scheduleNameValidateStatus = isValidName ? "success" : "error";
        this.scheduleConfigValidateStatus = isValidConfig ? "success" : "error";
      }
      return isValid;
    },
    next() {
      if (this.validate()) {
        return Promise.resolve(this.schedule);
      } else {
        return Promise.reject(this.schedule);
      }
    }
  }
};
</script>

<style lang="less" scoped>
.wrapper {
  max-width: 680px;
  margin: 36px auto;
  padding: 0 1.3em;

  /deep/ .ant-form-item-with-help {
    margin-bottom: 24px;
  }
}
</style>
