import Mail from "./AppMail";
import Wechat from "./AppWechat";
import WechatGroup from "./AppWechatGroup";
import Dingtalk from "./AppDingtalk";
import DingtalkGroup from "./AppDingtalkGroup";

export default {
  mail: Mail,
  wechat: Wechat,
  "wechat-group": WechatGroup,
  dingtalk: Dingtalk,
  "dingtalk-group": DingtalkGroup
};
