<template>
  <component
    ref="appForm"
    :is="appForm"
    :schedule="schedule"
    class="ndl-antd"
    @validate="$listeners.validate"
  />
</template>

<script>
import * as API from "@/api/datasheet";
import appFormRender from "./app-form";

export default {
  props: {
    schedule: Object
  },
  computed: {
    appForm() {
      /**
       * @type {import("../models/schedule-message").default}
       */
      const schedule = this.schedule;
      return appFormRender[schedule.appType] || null;
    }
  },
  methods: {
    async next() {
      await API.saveSchedule(this.schedule);
      this.$emit("confirm");
    },
    validate() {
      /**
       * @type {{ validate: () => void }}
       */
      const ref = this.$refs.appForm || {};
      if (typeof ref.validate === "function") {
        return ref.validate();
      }
      return false;
    }
  }
};
</script>

<style lang="less" scoped>
.ant-form {
  margin: 80px auto;
  max-width: 780px;
  /deep/ .ant-form-item {
    margin-bottom: 42px;
  }
}
</style>
