<template>
  <label
    class="upload"
    :style="{ backgroundImage: value ? `url(${value})` : null }"
  >
    <div v-if="loading" class="loading"></div>
    <div v-else-if="!value" class="placeholder"></div>
    <div v-else class="action-wrapper">
      <div class="action" @click.prevent="onOpen()">
        <i class="ndl-icon-zoom-in"></i>
        <span>查看大图</span>
      </div>
      <!-- 会自动触发 input 选择文件 -->
      <div class="action">
        <i class="ndl-icon-refresh-cw"></i>
        <span>重新选择</span>
      </div>
      <!-- .prevent 修饰符防止弹出选择文件窗口 -->
      <div class="action" @click.prevent="onClear()">
        <i class="ndl-icon-trash-2"></i>
        <span>移除图片</span>
      </div>
    </div>
    <input type="file" accept=".jpg,.png,.jpeg,.webp" @change="onChange" />
  </label>
</template>

<script>
import * as API from "@/api/common";

export default {
  props: {
    value: String
  },
  data() {
    return {
      loading: false
    };
  },
  methods: {
    onOpen() {
      window.open(this.value);
    },
    onClear() {
      this.$emit("input", "");
    },
    async onChange(evt) {
      const file = evt.target.files[0];
      if (!file) {
        return;
      }
      evt.target.value = "";
      // 小于 4m
      if (file.size > 1024 * 4096) {
        this.$message.warning("请选择小于 4MB 的图片");
        return;
      }
      this.loading = true;
      // const reader = new FileReader();
      // reader.onload = () => {
      //   const buffer = reader.result;
      //   const blob = new Blob([buffer], { type: file.type });
      //   this.url = URL.createObjectURL(blob);
      // };
      // reader.readAsArrayBuffer(file);
      // @todo: 上传图片
      const { data } = await API.uploadFile(file);
      this.$emit("input", data.url.replace("localhost", "192.168.1.15"));
      this.loading = false;
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "~@/assets/app.less";

.upload {
  display: block;
  width: 320px;
  height: 180px;
  margin: auto;
  cursor: pointer;
  border-radius: 4px;
  border: dashed 1px #ebebeb;
  transition: all 0.2s;
  background: center / contain no-repeat;
  &:hover {
    border-color: @blue-6;
  }
  input[type="file"] {
    opacity: 0;
    height: 0;
    width: 0;
    overflow: hidden;
  }
}
// 上传中提示、空图片提示
.loading,
.placeholder {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #fafafa;
  background-repeat: no-repeat;
  background-position: center 38%;
  &::after {
    color: @text-color-secondary;
    font-size: 13px;
    position: absolute;
    bottom: 20%;
    left: 0;
    right: 0;
    text-align: center;
  }
}
.loading {
  background-image: url(/images/loading-buffering.gif);
  background-size: 32px;
  &::after {
    content: "正在上传...";
  }
}
.placeholder {
  background-image: url(~@/assets/images/ai_upload2.svg);
  background-size: 44px;
  &::after {
    content: "支持JPG、PNG格式。建议分辨率 1068*455";
  }
}
// 操作按钮
.action-wrapper {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.52);
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
  .action {
    border-radius: 4px;
    cursor: pointer;
    width: 80px;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    i {
      font-size: 22px;
      margin-bottom: 8px;
    }
  }
  .action:hover {
    background: rgba(0, 0, 0, 0.35);
  }
}
.upload:hover .action-wrapper {
  opacity: 1;
}
</style>
