<template>
  <a-skeleton v-if="loading" active style="padding: 1em" />
  <a-empty
    v-else-if="!list.length"
    :image="ImageEmpty"
    :imageStyle="{ height: '200px' }"
  >
    <span slot="description" class="ndl-text-desc-small ndl-padding">
      快速创建推送任务，并自动化执行。
      <a href="">关于推送 <i class="ndl-icon-link"></i></a>
    </span>
  </a-empty>
  <div v-else class="list-wrapper">
    <ListItem
      v-for="item in list"
      :key="item.id"
      :schedule="item"
      @run="onRun"
      @stop="onStop"
      @select="onSelect(item)"
      @config="onSelect(item)"
      @remove="onRemove(item)"
      @history="$emit('visit', item)"
    />
  </div>
</template>

<script>
import schedule from "@/views/datamodel/models/schedule";
import ImageEmpty from "@/assets/images/ai_schedule.svg";
import ListItem from "./ScheduleListItem";
import editSchedule from "../editor";

export default {
  components: { ListItem },
  data() {
    return { ImageEmpty };
  },
  computed: {
    list() {
      const id = this.$route.params.workspaceId;
      return schedule.scheduleList.filter(item => item.workspaceId === id);
    },
    loading() {
      return schedule.loading;
    }
  },
  created() {
    schedule.polling.start();
  },
  beforeDestroy() {
    schedule.polling.stop();
  },
  methods: {
    onSelect(item) {
      editSchedule({ defaultSchedule: item });
    },
    async onRun(item, onFailed) {
      try {
        await schedule.run(item.id);
      } catch {
        onFailed();
        this.$message.error("运行失败");
      }
    },
    async onStop(item, onFailed) {
      try {
        await schedule.stop(item.id);
      } catch {
        onFailed();
        this.$message.error("取消失败");
      }
    },
    onRemove(item) {
      schedule.remove(item.id);
    }
  }
};
</script>
