import * as APIDatasheet from "@/api/datasheet";
import * as APITeam from "@/api/team";
import { memoize } from "@/utils/function";

/**
 * @type {(datasheetId: string) => Promise<any>}
 */
export const getDatasheetFields = memoize(async datasheetId => {
  const { data } = await APIDatasheet.getDatasheetColumns({ datasheetId });
  for (const item of data) {
    item.fieldType = item.fieldType || item.columnType;
  }
  return data;
});

/**
 * @type {(appType: string) => Promise<any>}
 */
export const getAppSpecification = memoize(async appType => {
  const res = await APIDatasheet.getAppSpecification(appType);
  return res.data;
});

/**
 * @type {(data: object) => Promise<any>}
 */
export const getAppSchema = memoize(
  async data => {
    const { data: resData } = await APIDatasheet.getAppSchema(data);
    const defaultStream = (resData.streams && resData.streams[0]) || {};
    const properties = defaultStream.stream.jsonSchema.properties || {};
    const schema = Object.keys(properties).map(columnName => {
      const fieldName = columnName;
      const meta = properties[columnName] || {};
      const columnType = meta.type;
      const fieldType = columnType;
      return { ...meta, fieldName, columnName, fieldType, columnType };
    });
    return schema;
  },
  data => JSON.stringify(data)
);

/**
 * 根据 schedule 生成默认的缓存内容。避免编辑任务时发出额外请求。
 * @param {import("./schedule-data").default} schedule
 */
export function initScheduleDataCache(schedule) {
  const syncConfig = schedule.syncConfig || {};
  const syncFields = syncConfig.syncFields || [];
  const mapOfSource = {};
  const mapOfDestination = {};
  for (const item of syncFields) {
    const { columnName, destinationName } = item;
    if (!mapOfSource[columnName]) {
      mapOfSource[columnName] = {
        columnName,
        columnType: item.columnType,
        fieldName: item.fieldName,
        fieldType: item.columnType
      };
    }
    if (!mapOfDestination[destinationName]) {
      mapOfDestination[destinationName] = {
        columnName: destinationName,
        columnType: item.destinationType,
        fieldName: destinationName,
        fieldType: item.destinationType
      };
    }
  }
  const sourceFields = Object.values(mapOfSource);
  const destinationFields = Object.values(mapOfDestination);
  if (sourceFields.length && schedule.datasheetId) {
    getDatasheetFields.cache.set(schedule.datasheetId, sourceFields);
  }
  if (destinationFields.length) {
    const { appType, appConfig } = schedule;
    const cacheKey = JSON.stringify({ appType, appConfig });
    getAppSchema.cache.set(cacheKey, destinationFields);
  }
}

/**
 * @type {() => Promise<any>}
 */
export const getTeamTree = memoize(async () => {
  const res = await APITeam.treeOfTeam();
  return res.data;
});

/**
 * @type {(teamId: string) => Promise<any>}
 */
export const getTeamMembers = memoize(async teamId => {
  const { data } = await APITeam.listMember({ teamId });
  return (data && data.data) || [];
});

export function clearCache() {
  getDatasheetFields.cache.clear();
  getAppSchema.cache.clear();
  getTeamTree.cache.clear();
  getTeamMembers.cache.clear();
}
