<template>
  <img class="app-icon" :src="url" />
</template>

<script>
import { getAppIcon } from "../util-applist";

export default {
  props: {
    // app icon 名称
    icon: String,
    // app type，不设置 icon 时可根据 type 查找预定义的 icon
    type: String
  },
  computed: {
    url() {
      if (!this.icon) {
        return getAppIcon(this.type);
      }
      return `images/${this.icon}.svg`;
    }
  }
};
</script>

<style lang="less" scoped>
@size: 32px;

.app-icon {
  border-radius: 4px;
  width: @size;
  height: @size;
}
</style>
