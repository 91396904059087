<template>
  <a-form-item
    :label="label"
    :required="required"
    :validate-status="validateStatus"
    help="支持 http 和 https，必需以 http:// 或者 https:// 开头"
  >
    <a-input v-model="config.url" placeholder="e.g. https://example.com/path">
      <a-select slot="addonBefore" v-model="config.method" style="width: 90px">
        <a-select-option
          v-for="item in MethodOptions"
          :key="item.key"
          :value="item.value"
        >
          {{ item.key }}
        </a-select-option>
      </a-select>
    </a-input>
  </a-form-item>
</template>

<script>
import { MethodOptions } from "./models/constant";

export default {
  props: {
    validateStatus: {
      type: String,
      default: "success"
    },
    required: Boolean,
    label: String,
    config: Object
  },
  data() {
    return {
      MethodOptions
    };
  }
};
</script>

<style lang="less" scoped>
/deep/ .ant-input-group-addon .ant-select .ant-select-selection {
  margin-right: 0;
}
</style>
