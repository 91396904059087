<template>
  <a-modal
    width="80%"
    centered
    :closable="false"
    :mask-closable="false"
    :keyboard="false"
    :bodyStyle="{
      height: '84vh',
      maxHeight: '760px',
      overflow: 'auto',
      paddingTop: '30px'
    }"
  >
    <Steps
      v-model="current"
      :schedule="schedule"
      @prev="prev($event)"
      @next="next()"
    />
    <component
      ref="stepComponent"
      class="ndl-antd"
      :is="currentStep"
      :schedule="schedule"
      @validate="isValid = $event"
      @confirm="onConfirm()"
    />
    <Footer
      slot="footer"
      :current="current"
      :disabled="!isValid"
      :loading="loading"
      @prev="prev()"
      @next="next()"
      @cancel="onCancel()"
    />
  </a-modal>
</template>

<script>
import Footer from "./EditorFooter";
import Steps from "./Steps";
import StepsData from "./type-data";
import StepsDataHttp from "./type-data-http";
import StepsMessage from "./type-msg";

import initSchedule from "./models";
import * as Utils from "./models/utils";

export default {
  components: { Steps, Footer },
  props: {
    defaultSchedule: Object,
    $resolve: Function,
    $reject: Function
  },
  data() {
    const schedule = initSchedule(this.defaultSchedule);
    return {
      schedule,
      current: schedule.id ? 2 : 0,
      isValid: false,
      loading: false
    };
  },
  computed: {
    currentStep() {
      const { pushType, appType } = this.schedule;
      switch (pushType) {
        case "data":
          if (appType === "http") {
            return StepsDataHttp[this.current] || null;
          }
          return StepsData[this.current] || null;
        case "message":
          return StepsMessage[this.current] || null;
        default:
          return null;
      }
    }
  },
  destroyed() {
    Utils.clearCache();
  },
  methods: {
    async next() {
      try {
        this.loading = true;
        /**
         * @type {{ next: () => Promise<any> }}
         */
        const stepComponent = this.$refs.stepComponent;
        await stepComponent.next();
        this.current = Math.min(this.current + 1, 2);
      } finally {
        this.loading = false;
      }
    },
    prev(step = 1) {
      /**
       * @type {{ prev?: () => void }}
       */
      const stepComponent = this.$refs.stepComponent;
      if (typeof stepComponent.prev === "function") {
        stepComponent.prev();
      }
      this.current = Math.max(this.current - step, 0);
    },
    onConfirm() {
      this.$resolve(this.schedule);
    },
    onCancel() {
      this.$reject();
    }
  }
};
</script>

<style lang="less" scoped>
.ant-modal-root /deep/ .ant-modal-footer {
  border-top-color: #ebebeb;
}
</style>
