// 类型(datasheet 工作表，table 表，connector 连接器，database 数据库)
export const typeToStyle = {
  database: {
    color_1: "#13c2c2",
    color_2: "#13c2c255",
    title: "数据库"
  },
  connector: {
    color_1: "#ea3ff7",
    color_2: "#ea3ff755",
    title: "连接器"
  },
  table: {
    color_1: "#1890ff",
    color_2: "#1890ff55",
    title: "数据表"
  },
  datasheet: {
    color_1: "#faad14",
    color_2: "#faad1455",
    title: "视图"
  },
  default: {
    color_1: "#faad14",
    color_2: "#faad1455",
    title: "未知"
  }
};
