<template>
  <div class="param">
    <a-input v-model="item.key" key="key" placeholder="key" />
    <span class="separator">:</span>
    <a-input v-model="item.value" key="value" placeholder="value" />
    <span class="remove" @click="$emit('remove')">
      <i class="ndl-icon-minus-circle"></i>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    item: Object
  }
};
</script>

<style lang="less" scoped>
@import (reference) "~@/assets/app.less";

.param {
  display: flex;
  align-items: center;
  padding: 12px;
  background: #fafafa;
  border-radius: 8px;
  margin-bottom: 12px;

  .separator,
  .remove {
    padding: 0 18px;
    flex-shrink: 0;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 700;
  }
  .remove:hover {
    color: @red-6;
    cursor: pointer;
  }
}
</style>
