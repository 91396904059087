<template>
  <a-form layout="vertical">
    <SyncFrequency
      v-model="schedule.schedule"
      :labels="['推送频率', '自定义推送频率', null, '手动推送']"
    />
    <a-form-item
      label="条件表达式"
      help="仅在满足输入的条件时才推送消息，例如 SUM(A0:A9) > 100"
    >
      <a-textarea
        v-model="schedule.pushConditions.expression"
        placeholder="请输入"
        :rows="6"
      />
    </a-form-item>
  </a-form>
</template>

<script>
import SyncFrequency from "@/views/connections/components/job-frequency";

export default {
  components: { SyncFrequency },
  props: {
    schedule: Object
  },
  created() {
    this.$emit("validate", true);
  },
  methods: {
    next() {}
  }
};
</script>

<style lang="less" scoped>
.ant-form {
  margin: 80px auto;
  max-width: 780px;
  /deep/ .ant-form-item {
    margin-bottom: 42px;
  }
}
</style>
