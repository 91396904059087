<template>
  <div class="wrapper">
    <Chip
      v-for="(item, index) in units"
      closable
      circle
      :key="index"
      :src="item.avatar"
      :label="item.name"
      style="margin-right: 12px"
      @close="onRemove(index, item)"
    />
    <a
      @click="onPick()"
      style="display: inline-block; line-height: 24px; vertical-align: top"
    >
      <i class="ndl-icon-plus"></i>
      <span>选择</span>
    </a>
  </div>
</template>

<script>
import Chip from "@/components/chip";
import pickUnits from "@/views/settings/components/unit-picker";
import UnitTypes from "@/constant/unit-types";
import * as AvatarUtils from "@/components/avatar";
import * as ArrayUtils from "@/utils/array";
import * as APIUtils from "../models/utils";

export default {
  components: { Chip },
  props: {
    value: Array
  },
  data() {
    return {
      teams: [],
      members: []
    };
  },
  computed: {
    units() {
      const teamMap = new Map(this.teams.map(item => [item.teamId, item]));
      const memberMap = new Map(
        this.members.map(item => [item.memberId, item])
      );
      return []
        .concat(this.value)
        .map(item => {
          if (!item) {
            return null;
          }
          return item.unitType === UnitTypes.TEAM
            ? teamMap.get(item.unitId)
            : memberMap.get(item.unitId);
        })
        .filter(Boolean);
    }
  },
  async created() {
    const teamTree = await APIUtils.getTeamTree();
    const teams = ArrayUtils.flatten([teamTree]);
    for (const item of teams) {
      item.avatar = "images/default_team.svg";
      item.name = item.teamName;
    }
    const members = await APIUtils.getTeamMembers(teamTree.teamId);
    for (const item of members) {
      if (!item.avatar) {
        const color = AvatarUtils.colorOf(item.memberId);
        item.avatar = AvatarUtils.text2svg(item.memberName, color);
      }
      item.name = item.memberName;
    }
    this.teams = teams;
    this.members = members;
  },
  methods: {
    onRemove(index) {
      this.value.splice(index, 1);
      this.$emit("change", this.value);
    },
    async onPick() {
      const checkedUnits = [].concat(this.value);
      const pickedUnits = await pickUnits({
        title: "选择团队或成员",
        showTeam: true,
        showMember: true,
        defaultChecked: item =>
          checkedUnits.some(
            unit => unit.unitType == item.unitType && unit.unitId == item.unitId
          )
      });
      const emitValues = pickedUnits.map(item => {
        return { unitId: item.unitId, unitType: item.unitType };
      });
      this.$emit("input", emitValues);
      this.$emit("change", emitValues);
    }
  }
};
</script>
