import Schedule from "./schedule";
import ScheduleData from "./schedule-data";
import ScheduleMessage from "./schedule-message";

export { Schedule, ScheduleData, ScheduleMessage };

export default function(opts) {
  const pushType = opts && opts.pushType;
  if (pushType === "data") {
    return new ScheduleData(opts);
  }
  if (pushType === "message") {
    return new ScheduleMessage(opts);
  }
  return new Schedule(opts);
}
