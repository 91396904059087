/**
 * @description 画图的的辅助函数集
 */

/**
 * 把字符串切割成固定长度和数量的字符串数组
 * @param {String} source 要切割的字符串
 * @param {Number} itemMaxLen 每个子串长度
 * @param {Number} itemMaxNum 字符串数组的长度
 * @returns Array
 */
export function slice(source = "", itemMaxLen = 20, itemMaxNum = 3) {
  let target = [];
  let curByteLen = 0; // 记录当前便利的子串长度，一个中文符号占 2 个长度，英文符号占 1 长度，切割一次就归零一次
  let startIndex = 0; // 记录字串每次的切割开始位置
  for (let i = 0; i < source.length; i++) {
    const char = source[i];
    if (isChina(char)) {
      curByteLen += 2;
    } else {
      curByteLen += 1;
    }

    if (curByteLen >= itemMaxLen) {
      // 字串长度达到 itemMaxLen
      target.push(source.slice(startIndex, i + 1));
      startIndex = i + 1;
      curByteLen = 0;
    } else if (i === source.length - 1) {
      // 已经遍历完字符串
      target.push(source.slice(startIndex));
    }
  }

  // 原字符串长度是否超出了范围
  let isEllipsis = false;

  // 根据 itemMaxNum 控制 target 的长度
  if (target.length > itemMaxNum) {
    target = target.slice(0, itemMaxNum);
    target[itemMaxNum - 1] = target[itemMaxNum - 1].replace(/.$/, "...");
    isEllipsis = true;
  }

  return [target, isEllipsis];
}

/**
 * 判断字符是否是中文字符
 * @param {String} char 单个字符
 * @returns Boolean
 */
export function isChina(char = "") {
  if (char.charCodeAt(0) > 255) {
    return true;
  }
  return false;
}
