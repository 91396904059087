import Units from "./Units";
import Upload from "./UploadImage";

export default {
  components: { Units, Upload },
  props: {
    schedule: Object
  },
  computed: {
    msgType() {
      return this.schedule.msgType;
    },
    msgBody() {
      return this.schedule.msgBody || {};
    }
  },
  created() {
    this.validate();
  },
  methods: {
    validate() {}
  }
};
