export const appList = [
  {
    appType: "vika",
    pushType: "data",
    icon: "app_vika",
    name: "推送到维格表",
    desc: "将数据写入 vika"
  },
  {
    appType: "feishu",
    pushType: "data",
    icon: "app_feishu",
    name: "推送到飞书多维表格",
    desc: "将数据写入飞书多维表格"
  },
  {
    appType: "http",
    pushType: "data",
    icon: "app_http",
    name: "HTTP 推送",
    desc: "向一个 URL 链接推送数据"
  },
  {
    appType: "wechat",
    pushType: "message",
    icon: "app_wechat",
    name: "推送到企业微信",
    desc: "流程触发时，自动推送消息到企业微信应用"
  },
  {
    appType: "dingtalk",
    pushType: "message",
    icon: "app_dingtalk",
    name: "推送到钉钉",
    desc: "流程触发时，自动推送消息到钉钉应用"
  },
  {
    appType: "mail",
    pushType: "message",
    icon: "app_mail",
    name: "推送到邮箱",
    desc: "流程触发时，自动推送消息到您的邮箱"
  },
  {
    appType: "wechat-group",
    pushType: "message",
    icon: "app_wechat_group",
    name: "推送到企业微信群聊",
    desc: "通过群聊机器人推送企业微信消息"
  },
  {
    appType: "dingtalk-group",
    pushType: "message",
    icon: "app_dingtalk_group",
    name: "推送到钉钉群聊",
    desc: "通过群聊机器人推送钉钉消息"
  }
];

export function getApp(appType) {
  return appList.find(item => item.appType === appType);
}

export function getAppIcon(appType) {
  const app = getApp(appType);
  return app ? `/images/${app.icon}.svg` : null;
}

export function getAppName(appType) {
  const app = getApp(appType);
  return app ? app.name : null;
}

export default appList;
