<template>
  <div class="footer">
    <div class="left">
      <a-button v-if="current" @click="$emit('prev')">
        上一步
      </a-button>
    </div>
    <div class="right">
      <a-button @click="$emit('cancel')">取消</a-button>
      <a-button
        type="primary"
        :disabled="disabled"
        :loading="loading"
        @click="$emit('next')"
      >
        {{ current === 2 ? "完成" : "继续" }}
      </a-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    current: Number,
    disabled: Boolean,
    loading: Boolean
  }
};
</script>

<style lang="less" scoped>
.footer {
  display: flex;
  justify-content: space-between;
}
</style>
