<template>
  <a-form layout="vertical">
    <SyncFrequency v-model="schedule.schedule" />
    <SyncMode :schedule="schedule" />
    <SyncFields v-if="schedule.appType !== 'http'" :schedule="schedule" />
  </a-form>
</template>

<script>
import * as API from "@/api/datasheet";
import SyncFrequency from "@/views/connections/components/job-frequency";
import SyncMode from "./SyncMode";
import SyncFields from "./SyncFields";
import { SyncConfig } from "../models/schedule-data";

export default {
  components: { SyncFrequency, SyncMode, SyncFields },
  props: {
    schedule: {
      type: Object,
      required: true
    }
  },
  created() {
    this.validate();
  },
  watch: {
    "schedule.schedule": {
      deep: true,
      handler() {
        this.validate();
      }
    },
    "schedule.syncConfig": {
      deep: true,
      handler() {
        this.validate();
      }
    }
  },
  methods: {
    async next() {
      await API.saveSchedule(this.schedule);
      this.$emit("confirm");
    },
    validate() {
      const { schedule, syncConfig } = this.schedule;
      const isValidSchedule = !schedule || (schedule && schedule.units);
      const isValidSyncConfig = SyncConfig.validate(syncConfig);
      this.$emit("validate", isValidSchedule && isValidSyncConfig);
    }
  }
};
</script>

<style lang="less" scoped>
.ant-form {
  margin: 80px auto;
  max-width: 780px;
  /deep/ .ant-form-item {
    margin-bottom: 42px;
  }
}
</style>
