<template>
  <Pane v-if="schedule">
    <ScheduleHistory :schedule="schedule" @back="schedule = null" />
  </Pane>
  <Pane v-else>
    <!-- 空数据提示 -->
    <ScheduleList ref="scheduleList" @visit="schedule = $event" />
    <!-- 应用列表 -->
    <AppList @select="createSchedule" />
  </Pane>
</template>

<script>
import schedule from "@/views/datamodel/models/schedule";
import workspace from "@/views/datamodel/models/workspace";
import editSchedule from "../editor";
import Pane from "../../pane-container";
import ScheduleHistory from "./History";
import ScheduleList from "./ScheduleList";
import AppList from "./AppList";

export default {
  components: { Pane, ScheduleHistory, ScheduleList, AppList },
  data() {
    return {
      schedule: null
    };
  },
  watch: {
    $route() {
      this.schedule = null;
    }
  },
  methods: {
    async createSchedule(appDef) {
      const defaultDatasheet = workspace.currentWorkspace.children[0];
      const datasheetId =
        workspace.currentDatasheetId ||
        (defaultDatasheet && defaultDatasheet.nodeId);
      if (!datasheetId) {
        this.$message.warning({
          content: "请先新建视图",
          icon: h => h("a-icon", { props: { type: "frown" } })
        });
        return;
      }
      const { appType, pushType } = appDef;
      const defaultSchedule = {
        appType,
        datasheetId,
        pushType
      };
      const data = await editSchedule({ defaultSchedule });
      // 点击确认按钮时 data 非空，其它方式关闭 modal 时 data 一般为空
      if (data) {
        schedule.refresh();
      }
    }
  }
};
</script>
