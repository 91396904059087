<template>
  <Pane :style="{ width: paneWidth }">
    <div class="kinship">
      <div id="kinship-container"></div>
      <div v-if="kinship" class="zoom-view">
        <span class="ndl-icon ndl-icon-zoom-out" @click="operZoom(-10)"></span>
        <span class="zoom">{{ kinship.zoom }}%</span>
        <span class="ndl-icon ndl-icon-zoom-in" @click="operZoom(10)"></span>
      </div>
      <div v-if="loading" class="loading">
        <a-icon type="loading" class="loading-icon" />
      </div>
    </div>
  </Pane>
</template>

<script>
import Pane from "../../pane-container";
import Kinship from "./draw";
import { getKinshipData } from "@/api/datasheet";
import { PANE_WIDTH } from "./constant";

import workspace from "../../../models/workspace";

export default {
  name: "Kinship",
  components: { Pane },
  data() {
    return {
      kinship: null,
      loading: false,
      paneWidth: PANE_WIDTH
    };
  },
  watch: {
    $route() {
      if (this.kinship) {
        this.getData();
      }
    },
    nodeName() {
      this.getData();
    }
  },
  computed: {
    nodeName() {
      let name = "";
      // 广度优先遍历空间树，用于监听空间树下左右节点的 nodeName 变化
      const queue = [];
      queue.push(workspace);
      while (queue.length) {
        const curNode = queue.pop();
        const children = curNode.children;
        name += curNode.nodeName;
        if (children) {
          children.forEach(child => {
            queue.unshift(child);
          });
        }
      }
      return name;
    }
  },
  mounted() {
    this.kinship = new Kinship("kinship-container");
    this.getData();
  },
  destroyed() {
    if (this.kinship) {
      this.kinship.destroy();
    }
  },
  methods: {
    operZoom(ratio) {
      let zoom = this.kinship.zoom;
      zoom += ratio;
      if (zoom < 20) {
        zoom = 20;
      }
      if (zoom > 1000) {
        zoom = 1000;
      }
      this.kinship.zoom = zoom;
    },
    getData() {
      const datasheetId = this.$route.params.datasheetId;
      if (datasheetId) {
        this.loading = true;
        this.kinship.render({});
        getKinshipData(datasheetId)
          .then(res => {
            this.kinship.render(this.transitionData(res.data || []));
          })
          .catch(err => {
            console.error("获取视图血缘关系失败", err);
            this.$message.error("获取视图血缘关系失败");
          })
          .finally(() => (this.loading = false));
      }
    },
    transitionData(kinshipList) {
      const data = { nodes: [], edges: [] };
      kinshipList.forEach(item => {
        data.nodes.push({
          id: item.id,
          nodeType: item.type,
          nodeName: item.name
        });
        if (item.parentId) {
          data.edges.push({
            source: item.parentId,
            target: item.id
          });
        }
      });
      return data;
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "~@/assets/app.less";

.pane-wrapper /deep/ .pane-body {
  padding: 0;
  overflow: hidden;
}

.kinship {
  height: 100%;
  width: 100%;
  position: relative;
  #kinship-container {
    height: 100%;
    width: 100%;
    &:active /deep/ canvas {
      cursor: grab !important;
    }
    /deep/ .g6-component-tooltip {
      width: 200px;
      background-color: #fff;
      border-radius: 6px;
      border: none;
      padding: 8px 10px;
      font-size: 14px;
      // color: #00287e;
      font-weight: 700;
      line-height: 23px;
      word-wrap: break-word;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.18);
    }
  }
  .loading {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    .loading-icon {
      color: @blue-6;
      font-size: 36px;
    }
  }
  .zoom-view {
    position: absolute;
    bottom: 25px;
    left: calc(50% - 55px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    box-shadow: 0 0 15px #ddd;
    box-sizing: border-box;
    width: 110px;
    height: 30px;
    padding: 5px 10px;
    border-radius: 15px;
    .zoom {
      color: #00287e;
      font-size: 13px;
      font-weight: bold;
      text-align: center;
      width: 50px;
    }
    .ndl-icon {
      font-size: 18px;
      color: #595959;
      cursor: pointer;
    }
  }
}
</style>
