<template>
  <div>
    <!-- 应用列表 -->
    <div class="divider">
      <span>快速创建推送任务</span>
    </div>
    <div
      v-for="item in appList"
      :key="item.appType"
      class="app-item"
      @click="$emit('select', item)"
    >
      <AppIcon :icon="item.icon" />
      <div class="app-meta">
        <span class="app-name">{{ item.name }}</span>
        <span class="app-desc">{{ item.desc }}</span>
      </div>
      <i class="app-arrow ndl-icon-chevron-right"></i>
    </div>
  </div>
</template>

<script>
import appList from "../util-applist";
import AppIcon from "./AppIcon";

export default {
  components: { AppIcon },
  data() {
    return { appList };
  }
};
</script>

<style lang="less" scoped>
@import (reference) "~@/assets/app.less";

.divider {
  margin: 80px 0 12px 6px;
  position: relative;
  user-select: none;
  font-weight: bold;
  color: #777;
}

.app-item {
  margin: 20px auto;
  max-width: 380px;
  padding: 12px;
  border-radius: 8px;
  box-shadow: 0 0 22px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    border-color: @blue-5;
    background: @blue-1;
  }
}
.app-meta {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}
.app-desc {
  flex-shrink: 0;
  color: #595959;
  font-size: 12px;
}
.app-arrow {
  color: #bfbfbf;
  margin-left: 8px;
}
.app-icon {
  margin: 0 18px 0 4px;
  flex-shrink: 0;
}
</style>
