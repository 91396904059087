<template>
  <a-form-item :label="label">
    <div>
      <Param
        v-for="(item, index) in list"
        :key="index"
        :item="item"
        @remove="onRemove(index)"
      />
    </div>
    <a-button type="primary" size="small" icon="plus" @click="onAdd()">
      添加一行
    </a-button>
  </a-form-item>
</template>

<script>
import Param from "./RequestParam";

export default {
  components: { Param },
  props: {
    label: String,
    params: Array
  },
  computed: {
    list() {
      if (!this.params.length) {
        this.onAdd();
      }
      return this.params;
    }
  },
  methods: {
    onAdd() {
      this.params.push({ key: "", value: "" });
    },
    onRemove(index) {
      this.params.splice(index, 1);
    }
  }
};
</script>
