<template>
  <a-form layout="vertical">
    <a-form-item label="接收者" required>
      <Units v-model="schedule.pushUnits" @change="validate()" />
    </a-form-item>
    <a-form-item label="主题" required>
      <a-input
        v-model="msgBody.title"
        placeholder="请输入邮件主题"
        @change="validate()"
      />
    </a-form-item>
    <a-form-item label="正文" required>
      <a-textarea
        v-model="msgBody.content"
        placeholder="请输入"
        :rows="12"
        @change="validate()"
      />
    </a-form-item>
  </a-form>
</template>

<script>
import mixin from "./app-mixin";

export default {
  mixins: [mixin],
  methods: {
    validate() {
      const pushUnits = this.schedule.pushUnits || [];
      if (!pushUnits.length) {
        return this.$emit("validate", false);
      }
      const { title, content } = this.msgBody || {};
      this.$emit("validate", Boolean(title && content));
    }
  }
};
</script>
