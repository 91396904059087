import { Graph, Grid, Tooltip } from "@antv/g6";
import debounce from "lodash/debounce";
import "./register-nodes";

class Kinship {
  constructor(selector) {
    // 获取容器元素
    this.container = document.getElementById(selector);
    // 实例化画布
    this.graph = new Graph({
      container: this.container,
      width: this.container.clientWidth,
      height: this.container.clientHeight,
      layout: {
        type: "dagre",
        rankdir: "LR",
        nodesep: 70,
        ranksep: 100,
        controlPoints: true
      },
      defaultNode: {
        type: "kinship-node"
      },
      defaultEdge: {
        type: "polyline",
        style: {
          offset: 45,
          radius: 20,
          endArrow: true,
          lineWidth: 2,
          stroke: "#C2C8D5"
        }
      },
      modes: {
        default: ["drag-canvas", "zoom-canvas"]
      },
      fitView: true,
      fitViewPadding: 20,
      plugins: [
        // 实例化 grid 插件，用于展示背景方格
        new Grid(),
        this.getTooltip()
      ]
    });

    // 容器元素大小发生变化的时候 注意修改画布大小
    const debounced = debounce(this.resizeHandler, 200);
    this.observer = new ResizeObserver(debounced);
    this.observer.observe(this.container);
  }

  // 缩放
  set zoom(zoom) {
    if (!this.graph) return;
    this.graph.zoomTo(zoom / 100, {
      x: this.container.clientWidth / 2,
      y: this.container.clientHeight / 2
    });
  }
  get zoom() {
    if (!this.graph) return 100;
    return Math.round(this.graph.getZoom() * 100);
  }

  // 初始化提示信息面板
  getTooltip() {
    return new Tooltip({
      offsetX: 20,
      offsetY: 20,
      itemTypes: ["node"],
      getContent: event => {
        return event.item.getModel().nodeName;
      },
      shouldBegin: event => {
        return event.item.getModel().isEllipsis;
      }
    });
  }

  // 容器元素大小发生变化的时候，修改画布的大小
  resizeHandler = () => {
    if (!this.graph || this.graph.get("destroyed")) return;
    if (
      !this.container ||
      !this.container.clientWidth ||
      !this.container.clientHeight
    ) {
      return;
    }
    this.graph.changeSize(
      this.container.clientWidth,
      this.container.clientHeight
    );
  };

  // 根据 data 渲染图像，具体结构请看 ./example-data.js
  render(data = {}) {
    if (!this.graph) return;
    this.graph.clear();
    this.graph.data(data);
    this.graph.render();
  }

  // 解绑监听、销毁 graph 实例
  destroy() {
    if (this.observer) this.observer.disconnect();
    if (this.graph) this.graph.destroy();
  }
}

export default Kinship;
