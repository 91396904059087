<template>
  <!-- https://open.dingtalk.com/document/orgapp-server/message-types-and-data-format -->
  <a-form layout="vertical">
    <a-form-item label="接收者" required>
      <Units v-model="schedule.pushUnits" @change="validate()" />
    </a-form-item>
    <a-form-item label="消息类型" required>
      <a-radio-group v-model="schedule.msgType" @change="validate()">
        <a-radio value="action_card">图文消息</a-radio>
        <a-radio value="text">文本消息</a-radio>
        <a-radio value="markdown">Markdown</a-radio>
      </a-radio-group>
    </a-form-item>
    <!-- msgType: action_card 图文消息 -->
    <template v-if="msgType === 'action_card'">
      <a-form-item label="消息标题" required>
        <a-input
          v-model="msgBody.title"
          placeholder="请输入标题"
          @change="validate()"
        />
      </a-form-item>
      <a-form-item label="消息摘要" required>
        <a-textarea
          v-model="msgBody.content"
          placeholder="请输入"
          :rows="6"
          @change="validate()"
        />
      </a-form-item>
      <a-form-item label="消息链接" required help="消息点击后跳转的链接">
        <a-input
          v-model="msgBody.url"
          placeholder="请输入链接"
          @change="validate()"
        />
      </a-form-item>
      <a-form-item label="消息封面">
        <Upload v-model="msgBody.cover" />
      </a-form-item>
    </template>
    <!-- msgType: text 文本消息 -->
    <template v-else-if="msgType === 'text'">
      <a-form-item label="消息内容" required help="消息内容，建议500字符以内。">
        <a-textarea
          v-model="msgBody.content"
          placeholder="请输入"
          :rows="6"
          @change="validate()"
        />
      </a-form-item>
    </template>
    <!-- msgType: markdown Markdown -->
    <template v-else-if="msgType === 'markdown'">
      <a-form-item label="消息标题" required help="首屏会话透出的展示内容">
        <a-input
          v-model="msgBody.title"
          placeholder="请输入标题"
          @change="validate()"
        />
      </a-form-item>
      <a-form-item label="消息正文" required>
        <a-textarea
          v-model="msgBody.content"
          placeholder="请输入"
          :rows="10"
          @change="validate()"
        />
        <div slot="help">
          <span>支持 Markdown 语法，</span>
          <a
            href="https://open.dingtalk.com/document/orgapp-server/message-types-and-data-format"
            target="__blank"
          >
            帮助文档
          </a>
        </div>
      </a-form-item>
    </template>
  </a-form>
</template>

<script>
import mixin from "./app-mixin";

export default {
  mixins: [mixin],
  created() {
    // 设置默认消息类型
    if (!this.msgType) {
      this.schedule.msgType = "action_card";
    }
  },
  methods: {
    validate() {
      const pushUnits = this.schedule.pushUnits || [];
      if (!pushUnits.length) {
        return this.$emit("validate", false);
      }
      const { title, content, url } = this.msgBody || {};
      switch (this.msgType) {
        case "action_card":
          return this.$emit("validate", Boolean(title && content && url));
        case "text":
          return this.$emit("validate", Boolean(content));
        case "markdown":
          return this.$emit("validate", Boolean(title && content));
        default:
          return this.$emit("validate", true);
      }
    }
  }
};
</script>
