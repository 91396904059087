import { Method, AuthType, AppendPosition, Oauth2Type } from "./constant";

class BasicAuth {
  constructor(auth) {
    auth = auth || {};
    this.username = auth.username || ""; // 用户名
    this.password = auth.password || ""; // 密码
  }
}

class BearerToken {
  constructor(auth) {
    auth = auth || {};
    this.value = auth.value || ""; // token
    this.tokenPrefix = auth.tokenPrefix || "Bearer"; // token 前缀
    this.path = auth.path || AppendPosition.HEADER; // 追加位置
  }
}

class ApiKey {
  constructor(auth) {
    auth = auth || {};
    this.key = auth.key || ""; // key
    this.value = auth.value || ""; // value
    this.path = auth.path || AppendPosition.HEADER; // 追加位置
  }
}

class Oauth2P extends ApiKey {
  constructor(auth) {
    super(auth);
    // key: AccessToken参数名
    // value: AccessToken
    // path: 追加位置
  }
}

class Oauth2T extends Oauth2P {
  constructor(auth) {
    super(auth);
    auth = auth || {};
    this.method = auth.method || Method.POST; // 请求方法
    this.url = auth.url || ""; // 请求 URL
    this.header = auth.header || []; // 请求 Header
    this.query = auth.query || []; // 请求 Query
    this.valueName = auth.valueName || "access_token"; // Token Response Key
  }
}

export class Auth {
  constructor(auth) {
    auth = auth || {};
    // 认证方式
    this.authType = auth.authType || AuthType.NONE;
    // 根据认证方式创建对应属性
    switch (this.authType) {
      case AuthType.BASIC_AUTH:
        Object.assign(this, new BasicAuth(auth));
        break;
      case AuthType.BEARER_TOKEN:
        Object.assign(this, new BearerToken(auth));
        break;
      case AuthType.API_KEY:
        Object.assign(this, new ApiKey(auth));
        break;
      case AuthType.OAUTH2:
        // AccessToken的状态 临时/永久(temporary/permanent)
        this.accessTokenType = auth.accessTokenType || Oauth2Type.PERMANENT;
        if (this.accessTokenType === Oauth2Type.PERMANENT) {
          Object.assign(this, new Oauth2P(auth));
        } else {
          Object.assign(this, new Oauth2T(auth));
        }
    }
  }
}

export default class HttpAppConfig {
  constructor(config) {
    config = config || {};
    const { method, url, header, query, auth, body, raw } = config;
    // 请求方法
    this.method = method || Method.POST;
    // 请求 URL
    this.url = url || "";
    // 请求 Header
    this.header = header || [];
    // 请求 Query
    this.query = query || [];
    // 认证信息
    this.auth = auth || new Auth();
    // 请求 Body (内容是对象的时候传它)
    this.body = body || {};
    // 请求 Body (存放 body 的 json 字符串, 注意 body 与 raw 二选一)
    this.raw = raw || "";
  }

  static validate(config) {
    const { method, url } = config;
    if (!method || !url) {
      return false;
    }
    if (url && !url.startsWith("http://") && !url.startsWith("https://")) {
      return false;
    }
    return true;
  }
}
