import * as monaco from "monaco-editor";

export const LANG_ID = "json";
export function registerCompletionItemProvider(list = []) {
  monaco.languages.registerCompletionItemProvider(LANG_ID, {
    provideCompletionItems: () => {
      const suggestions = list.map(item => {
        return {
          label: `<> ${item}`,
          kind: monaco.languages.CompletionItemKind.Text,
          insertText: `"<${item}>"`
        };
      });
      return { suggestions: suggestions };
    }
  });
}
