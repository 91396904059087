import Vue from "vue";
import Polling from "@/views/connections/models/polling";
import * as API from "@/api/datasheet";
import * as helper from "@/views/connections/models/helper";

export class Schedule {
  constructor() {
    this.scheduleList = [];
    this.statusList = [];
    this.loading = true;
    this.polling = new Polling();
    this.polling.push(this.refresh.bind(this), {
      leading: false,
      interval: 3000
    });
    this.polling.push(this.updateStatus.bind(this), {
      leading: false,
      interval: 1500
    });
    this.polling.stop();
  }

  async refresh() {
    const { data } = await API.getScheduleList();
    helper.normalizeConnections(data, this.scheduleList);
    this.scheduleList = data;
    helper.updateConnectionStatus(this.scheduleList, this.statusList);
    this.loading = false;
  }
  async updateStatus() {
    const { data } = await API.getScheduleStatus();
    helper.updateConnectionStatus(this.scheduleList, data);
    this.statusList = data;
  }

  getSchedule(scheduleId) {
    return this.scheduleList.find(item => item.id === scheduleId) || null;
  }

  run(scheduleId) {
    return API.runSchedule(scheduleId);
  }
  stop(scheduleId) {
    return API.stopSchedule(scheduleId);
  }
  reset(scheduleId) {
    return API.resetSchedule(scheduleId);
  }
  async remove(scheduleId) {
    await API.deleteSchedule(scheduleId);
    this.scheduleList = this.scheduleList.filter(
      item => item.id !== scheduleId
    );
  }
}

const schedule = new Schedule();

export default Vue.observable(schedule);
