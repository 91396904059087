<template>
  <div class="mapping-row">
    <a-select
      v-model="field.columnName"
      option-label-prop="label"
      placeholder="请选择"
    >
      <a-select-option
        v-for="item in sourceFields"
        :key="item.columnName"
        :value="item.columnName"
        :label="item.columnName"
        class="ndl-flex"
        @click.native="onSelectSource(item)"
      >
        <span class="ndl-flex-item--grow">
          {{ item.columnName }}
        </span>
        <span class="ndl-flex-item--noshrink ndl-color-gray">
          {{ item.format || item.columnType }}
        </span>
      </a-select-option>
    </a-select>
    <span class="arrow">
      <i class="ndl-icon-arrow-right"></i>
    </span>
    <a-select
      v-model="field.destinationName"
      option-label-prop="label"
      placeholder="请选择"
    >
      <a-select-option
        v-for="item in destinationFields"
        :key="item.columnName"
        :value="item.columnName"
        :label="item.columnName"
        class="ndl-flex"
        @click.native="onSelectDestination(item)"
      >
        <span class="ndl-flex-item--grow">
          {{ item.columnName }}
        </span>
        <span class="ndl-flex-item--noshrink ndl-color-gray">
          {{ item.format || item.columnType }}
        </span>
      </a-select-option>
    </a-select>
    <span class="remove" @click="$emit('remove')">
      <i class="ndl-icon-minus-circle"></i>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    field: Object,
    sourceFields: Array,
    destinationFields: Array
  },
  methods: {
    onSelectSource(item) {
      const field = this.field;
      field.fieldName = item.fieldName;
      field.fieldType = item.fieldType;
      field.columnType = item.columnType;
      field.columnId = item.columnId;
    },
    onSelectDestination(item) {
      const field = this.field;
      field.destinationType = item.fieldType;
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "~@/assets/app.less";

.mapping-row {
  display: flex;
  align-items: center;
  padding: 12px;
  padding-right: 0;
  background: #fafafa;
  border-radius: 8px;
  margin-bottom: 12px;

  .ant-select {
    width: 0;
    flex: 1 1 0;
  }
  .arrow,
  .remove {
    padding: 0 18px;
    flex-shrink: 0;
    color: rgba(0, 0, 0, 0.45);
  }
  .remove:hover {
    color: @red-6;
    cursor: pointer;
  }
}
</style>
