<template>
  <div :class="['record', { expanded }]">
    <div class="record-thumb" @click="expanded = !expanded">
      <Status :record="record" :show-description="showDescription">
        <span slot="title" slot-scope="props">
          <span>{{ props.status }} - </span>
          <span>{{ createdAt }}</span>
        </span>
      </Status>
      <span class="arrow">
        <i class="ndl-icon-chevron-right"></i>
      </span>
    </div>
    <Terminal v-if="expanded" :record="record" :get-job-log="getJobLog" />
  </div>
</template>

<script>
import * as API from "@/api/datasheet";
import Status from "@/views/connections/components/record-status";
import Terminal from "@/views/connections/components/job-terminal";

export default {
  components: { Status, Terminal },
  props: {
    record: Object,
    showDescription: Boolean
  },
  data() {
    return {
      expanded: false
    };
  },
  computed: {
    createdAt() {
      const job = this.record.job || {};
      return job.createdAt;
    }
  },
  methods: {
    async getJobLog(job) {
      const { data } = await API.getScheduleLog(job.id);
      // 优化显示后端返回的 json 字符串中的换行符
      const attempts = (data && data.attempts) || [];
      for (const item of attempts) {
        const logs = item.logs || {};
        logs.logLines = logs.logLines.reduce(
          (result, line) => result.concat(line.split("\n")),
          []
        );
      }
      return data;
    }
  }
};
</script>

<style lang="less" scoped>
.record {
  margin: 20px 0;
  border-radius: 8px;
  box-shadow: 0 0 22px rgba(0, 0, 0, 0.08);

  .record-thumb {
    height: 60px;
    padding: 10px 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  .status {
    flex: 1 1 0;
  }
  .arrow {
    flex-shrink: 0;
    transition: transform 0.2s;
    color: fade(black, 45%);
  }
  &.expanded .arrow {
    transform: rotate(90deg);
  }
}
</style>
