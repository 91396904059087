<template>
  <a-form-item :label="label">
    <!-- 认证方式 -->
    <a-select v-model="value.authType" @change="onChange">
      <a-select-option
        v-for="item in AuthTypeOptions"
        :key="item.key"
        :value="item.value"
      >
        {{ item.key }}
      </a-select-option>
    </a-select>
    <!-- 不同认证方式的不同表单 -->
    <div v-if="value.authType !== AuthType.NONE" class="auth-from">
      <!-- 欺骗浏览器自动填充 -->
      <div style="height: 0; overflow: hidden; position: fixed; left: -9999px;">
        <input type="text" />
        <input type="password" />
      </div>
      <component :is="authComponent" :config="value" />
    </div>
  </a-form-item>
</template>

<script>
import { AuthTypeOptions, AuthType, Oauth2Type } from "./models/constant";
import { Auth } from "./models/http-app-config";
import * as AuthComponents from "./auth-components";

export default {
  components: { ...AuthComponents },
  props: {
    label: String,
    value: Object
  },
  data() {
    return {
      AuthTypeOptions,
      AuthType
    };
  },
  watch: {
    "value.accessTokenType"() {
      this.onChange();
    }
  },
  computed: {
    authComponent() {
      switch (this.value.authType) {
        case AuthType.BASIC_AUTH:
          return AuthComponents.BasicAuth;
        case AuthType.BEARER_TOKEN:
          return AuthComponents.BearerToken;
        case AuthType.API_KEY:
          return AuthComponents.ApiKey;
        case AuthType.OAUTH2:
          if (this.value.accessTokenType === Oauth2Type.PERMANENT) {
            return AuthComponents.Oauth2P;
          } else {
            return AuthComponents.Oauth2T;
          }
      }
      return "div";
    }
  },
  methods: {
    onChange() {
      this.$emit("input", new Auth(this.value));
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "~@/assets/app.less";

.auth-from {
  padding: 24px;
  padding-bottom: 0;
  // background: #fafafa99;
  border: 1px dashed @blue-2;
  border-radius: 4px;
  margin-top: 12px;

  /deep/ .ant-input-group-addon .ant-select .ant-select-selection {
    margin-right: 0;
  }
}
</style>
