<template>
  <div v-if="list.length" class="ndl-margin-bottom-lg">
    <h4>
      <i class="ndl-icon-history-line"></i>
      <span> 使用历史应用设置</span>
    </h4>
    <a-row :gutter="20" :class="{ expanded }">
      <a-col v-for="item in list" :key="item.id" :span="8">
        <div :title="item.name" class="list-item" @click="onClick(item)">
          <AppIcon :type="appType" />
          <span class="label">{{ item.name }}</span>
        </div>
      </a-col>
      <div v-if="!expanded" class="expand-rel">
        <a @click="expanded = true">
          显示全部
          <i class="ndl-icon-chevrons-down"></i>
        </a>
      </div>
    </a-row>
  </div>
</template>

<script>
import schedule from "@/views/datamodel/models/schedule";
import cloneDeep from "lodash/cloneDeep";
import AppIcon from "@/views/datamodel/components/pane-schedule/src/AppIcon";

export default {
  components: { AppIcon },
  props: {
    appType: String
  },
  data() {
    return {
      expanded: false
    };
  },
  computed: {
    list() {
      const appType = this.appType;
      return schedule.scheduleList.filter(item => item.appType === appType);
    }
  },
  created() {
    // 一行只有 3 个应用时默认展开全部
    this.expanded = this.list.length <= 3;
  },
  methods: {
    onClick(item) {
      this.$emit("select", cloneDeep(item.appConfig));
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "~@/assets/app.less";

.ant-row {
  height: 100px;
  overflow: hidden;
  position: relative;
  .ant-col {
    margin: 10px 0;
  }
  &.expanded {
    height: auto;
  }
  .expand-rel {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 25px;
    text-align: center;
    background: linear-gradient(0, #fff, fade(#fff, 45%));
  }
}

.list-item {
  padding: 10px 14px;
  border-radius: 10px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    background: @blue-1;
  }
  .app-icon {
    flex-shrink: 0;
    width: 28px;
    height: 28px;
  }
  .label {
    flex: 1 1 0;
    margin-left: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
