<template>
  <div class="wrapper">
    <div class="list-item" @click="$emit('select')">
      <AppIcon :type="schedule.appType" />
      <div class="content" :title="title">
        <span class="content-label">{{ schedule.name }}</span>
        <div v-if="schedule.isJobStatusLoading" class="content-desc">
          <a-icon type="loading" />
        </div>
        <div v-else-if="schedule.latestSyncJobCreatedAt" class="content-desc">
          <JobStatus
            :status="schedule.latestSyncJobStatus"
            size="12px"
            style="margin-right: 4px; vertical-align: -1px;"
          />
          <span>最近运行于 {{ schedule.latestSyncJobCreatedAt }}</span>
        </div>
        <span v-else class="content-desc content-disabled">无运行记录</span>
      </div>
      <Focusable class="arrow" @click="$emit('history')">
        <i class="ndl-icon-chevron-right"></i>
      </Focusable>
    </div>
    <div class="actions">
      <Focusable @click="$emit('config')">
        <i class="ndl-icon-settings"></i>
        <span class="label">设置</span>
      </Focusable>
      <Focusable v-if="stopping" class="danger">
        <a-icon type="loading" />
        <span class="label">正在取消</span>
      </Focusable>
      <Focusable
        v-else-if="schedule.isSyncing"
        @click="onStop()"
        class="danger"
      >
        <i class="ndl-icon-minus-circle"></i>
        <span class="label">取消任务</span>
      </Focusable>
      <Focusable v-else-if="running">
        <a-icon type="loading" />
        <span class="label">正在运行</span>
      </Focusable>
      <Focusable v-else @click="onRun()">
        <i class="ndl-icon-refresh-cw"></i>
        <span class="label">立即运行</span>
      </Focusable>
      <Focusable @click="$emit('history')">
        <i class="ndl-icon-history-line"></i>
        <span class="label">历史记录</span>
      </Focusable>
      <Focusable @click="onRemove()" class="danger">
        <i class="ndl-icon-x-circle"></i>
        <span class="label">删除</span>
      </Focusable>
    </div>
  </div>
</template>

<script>
import workspace from "@/views/datamodel/models/workspace";
import Focusable from "@/views/datamodel/components/focusable";
import JobStatus from "@/views/connections/components/job-status";
import AppIcon from "./AppIcon";

export default {
  components: { Focusable, JobStatus, AppIcon },
  props: {
    schedule: Object
  },
  data() {
    return {
      running: false,
      stopping: false
    };
  },
  computed: {
    title() {
      const { datasheetId, name } = this.schedule || {};
      const datasheet = workspace.getNodeById(datasheetId);
      const datasheetName = datasheet ? datasheet.nodeName : "-";
      return `任务名称：${name || "-"}\n已选视图：${datasheetName}`;
    }
  },
  watch: {
    "schedule.isSyncing"() {
      this.running = false;
      this.stopping = false;
    }
  },
  methods: {
    onStop() {
      const schedule = this.schedule;
      this.stopping = true;
      const onFailed = () => {
        this.stopping = false;
      };
      this.$emit("stop", schedule, onFailed);
    },
    onRun() {
      const schedule = this.schedule;
      this.running = true;
      const onFailed = () => {
        this.running = false;
      };
      this.$emit("run", schedule, onFailed);
    },
    onRemove() {
      this.$confirm({
        title: "删除任务",
        content: `删除操作无法撤销，确定要删除此任务吗？`,
        okType: "danger",
        onOk: async () => {
          this.$emit("remove", this.schedule);
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "~@/assets/app.less";

.wrapper {
  margin: 0 8px;
  padding: 22px 14px 8px;
  border-bottom: solid 1px #ebebeb;
  &:hover .content-label {
    color: @blue-6;
  }
}
.list-item {
  cursor: pointer;
  display: flex;
  align-items: center;
  .app-icon,
  .arrow {
    flex-shrink: 0;
  }
  .arrow {
    color: #bfbfbf;
  }
  &:hover .arrow {
    color: fade(black, 65%);
  }
  .content {
    flex: 1 1 0;
    display: flex;
    flex-direction: column;
    margin: 0 12px;
    overflow: hidden;
  }
  .content-label,
  .content-desc {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 24px;
  }
  .content-desc {
    font-size: 13px;
    color: fade(black, 45%);
  }
  .content-disabled {
    font-style: italic;
  }
}
.actions {
  display: flex;
  flex-wrap: wrap;
  color: fade(black, 65%);
  margin-top: 18px;
  .focusable {
    flex-shrink: 0;
    width: auto;
    padding: 0 6px;
    margin: 0;
    margin-right: 8px;
  }
  .label {
    margin-left: 4px;
    font-size: 13px;
  }
  .focusable.disabled {
    cursor: not-allowed;
    opacity: 0.65;
  }
  .focusable.danger {
    color: @red-6;
  }
}
</style>
