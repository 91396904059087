import * as Utils from "./utils";
import Schedule from "./schedule";

export class Message {
  constructor(opts) {
    opts = opts || {};
    this.title = opts.title || null;
    this.titleColor = opts.titleColor || null;
    this.titleFontSize = opts.titleFontSize || null;
    this.cover = opts.cover || null;
    this.url = opts.url || null;
    this.content = opts.content || null;
  }
}

export default class ScheduleMessage extends Schedule {
  constructor(opts) {
    opts = opts || [];
    super(opts);
    this.pushType = "message";
    this.pushUnits = opts.pushUnits || [];
    this.pushConditions = Object.assign(
      {
        expression: ""
      },
      opts.pushConditions
    );
    // 默认为 null 便于在组件内个性化默认消息类型
    this.msgType = opts.msgType || null;
    this.msgBody = new Message(opts.msgBody);
  }
  getAppSpecification() {
    return Utils.getAppSpecification(this.appType);
  }
}
