<template>
  <a-form-item
    :label="label"
    help='请粘贴请求 json 内容，键入<>可快速选择视图列名；使用类似 { "myFieldName": "<列名>" } 的形式进行字段映射'
  >
    <JsonEditor
      v-model="jsonValue"
      :completion-provider-list="completionProviderList"
    />
  </a-form-item>
</template>

<script>
import JsonEditor from "./JsonEditor";

export default {
  components: { JsonEditor },
  props: {
    label: String,
    value: String,
    completionProviderList: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    jsonValue: {
      get() {
        return this.value || "";
      },
      set(jsonStr) {
        this.$emit("input", jsonStr);
      }
    }
  }
};
</script>
