<template>
  <a-row :gutter="24">
    <!-- 同步模式 -->
    <a-col :span="8">
      <a-form-item label="同步模式" required>
        <a-select v-model="syncConfig.destinationSyncMode" placeholder="请选择">
          <a-select-option v-for="item in syncModes" :key="item" :value="item">
            {{ item | localeMode }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-col>
    <!-- 主键，仅 append 模式下需要设置 -->
    <a-col :span="8" v-if="showCursorField">
      <a-form-item label="同步标识" required>
        <a-select
          v-model="syncConfig.cursorField"
          placeholder="请选择"
          option-label-prop="label"
        >
          <a-select-option
            v-for="item in datasheetFields"
            :key="item.columnName"
            :value="item.columnName"
            :label="item.columnName"
            class="ndl-flex"
          >
            <span class="ndl-flex-item--grow">
              {{ item.columnName }}
            </span>
            <span class="ndl-flex-item--noshrink ndl-color-gray">
              {{ item.format || item.columnType }}
            </span>
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-col>
    <!-- 主键，仅 append_dedup 模式下需要设置 -->
    <a-col :span="8" v-if="showPrimaryKeys">
      <a-form-item label="主键" required>
        <a-select
          v-model="syncConfig.primaryKeys"
          mode="multiple"
          placeholder="请选择"
          option-label-prop="label"
        >
          <a-select-option
            v-for="item in datasheetFields"
            :key="item.columnName"
            :value="item.columnName"
            :label="item.columnName"
            class="ndl-flex"
          >
            <span class="ndl-flex-item--grow">
              {{ item.columnName }}
            </span>
            <span class="ndl-flex-item--noshrink ndl-color-gray">
              {{ item.format || item.columnType }}
            </span>
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-col>
  </a-row>
</template>

<script>
import { SyncModes, SyncConfig } from "../models/schedule-data";

export default {
  props: {
    /**
     * @type {import("../models/schedule-data").default}
     */
    schedule: Object
  },
  data() {
    return {
      syncModes: [
        SyncModes.OVERWRITE,
        SyncModes.APPEND,
        SyncModes.APPEND_DEDUP
      ],
      datasheetFields: []
    };
  },
  computed: {
    syncConfig() {
      return this.schedule.syncConfig || {};
    },
    showCursorField() {
      return this.syncConfig.destinationSyncMode !== SyncModes.OVERWRITE;
    },
    showPrimaryKeys() {
      return this.syncConfig.destinationSyncMode === SyncModes.APPEND_DEDUP;
    }
  },
  async created() {
    const schedule = this.schedule;
    schedule.getAppSpecification().then(spec => {
      const syncModes = spec.supportedDestinationSyncModes;
      this.syncModes = syncModes;
      // 获取到支持的默认同步模式后，判断当前的模式是否被支持，不被支持就默认拿第一个被支持的模式
      const { destinationSyncMode } = this.schedule.syncConfig;
      if (syncModes.length && !syncModes.includes(destinationSyncMode)) {
        const syncConfig = { destinationSyncMode: syncModes[0] };
        this.schedule.syncConfig = new SyncConfig(syncConfig);
      }
    });
    schedule.getDatasheetFields().then(fields => {
      this.datasheetFields = fields;
    });
    schedule.on("change:datasheet.fields", fields => {
      this.datasheetFields = fields;
    });
  },
  beforeDestroy() {
    this.schedule.off("change:datasheet.fields");
  },
  filters: {
    localeMode: SyncModes.locale
  }
};
</script>
