/**
 * @description 注册自定义节点
 */

import { registerNode } from "@antv/g6";
import { slice } from "./draw-utils";
import { typeToStyle } from "./type-to-style";

registerNode("kinship-node", {
  drawShape(cfg, group) {
    let style = typeToStyle[cfg.nodeType];
    if (!style) {
      style = typeToStyle.default;
    }

    const rect = group.addShape("rect", {
      attrs: {
        x: -90,
        y: -75,
        width: 180,
        height: 150,
        radius: 10,
        stroke: style.color_1,
        fill: style.color_1,
        lineWidth: 2
      },
      name: "rect-shape-main"
    });

    group.addShape("rect", {
      attrs: {
        x: -88,
        y: -41,
        width: 176,
        height: 114,
        radius: 10,
        fill: "#ffffff"
      },
      name: "rect-shape"
    });

    group.addShape("text", {
      attrs: {
        text: `${style.title}`,
        x: 0,
        y: -57,
        fill: "#ffffff",
        fontSize: 16,
        textAlign: "center",
        textBaseline: "middle"
      },
      name: "title-shape"
    });

    if (cfg.nodeName) {
      const res = slice(cfg.nodeName);
      const names = res[0];
      cfg.isEllipsis = res[1]; // 用于判断是否出现弹窗提示
      let yNum = [17];
      if (names.length === 2) {
        yNum = [0, 30];
      }
      if (names.length === 3) {
        yNum = [-13, 17, 47];
      }

      names.forEach((item, index) => {
        group.addShape("text", {
          attrs: {
            text: item,
            x: 0,
            y: yNum[index],
            fill: "#00287E",
            fontSize: 14,
            textAlign: "center",
            textBaseline: "middle"
          },
          name: "text-shape"
        });
      });
    }
    return rect;
  }
});
