import * as Utils from "./utils";
import Schedule from "./schedule";

export const SyncModes = {
  OVERWRITE: "overwrite",
  APPEND: "append",
  APPEND_DEDUP: "append_dedup",
  locale(mode) {
    switch (mode) {
      case SyncModes.OVERWRITE:
        return "重写";
      case SyncModes.APPEND:
        return "追加";
      case SyncModes.APPEND_DEDUP:
        return "更新";
      default:
        return null;
    }
  }
};

export class SyncConfig {
  constructor(opts) {
    opts = opts || {};
    // 同步模式，overwrite | append | append_dedup(更新)
    this.destinationSyncMode = opts.destinationSyncMode || SyncModes.OVERWRITE;
    this.primaryKeys = opts.primaryKeys || [];
    this.syncFields = opts.syncFields || [];
    // 默认 为 undefined 而非 null，因为 <a-select /> 组件认为 undefined 才是真正的空值
    this.cursorField = opts.cursorField || undefined;
  }
  static validate(data) {
    const conf = new SyncConfig(data);
    switch (conf.destinationSyncMode) {
      case SyncModes.OVERWRITE:
        return true;
      case SyncModes.APPEND:
        return Boolean(conf.cursorField);
      case SyncModes.APPEND_DEDUP:
        return Boolean(conf.cursorField) && Boolean(conf.primaryKeys.length);
      default:
        return true;
    }
  }
}

export default class ScheduleData extends Schedule {
  constructor(opts) {
    super(opts);
    opts = opts || {};
    // 推送数据时的字段映射信息
    this.syncConfig = new SyncConfig(opts.syncConfig);

    /**
     * 用于保存一些额外信息的属性，不发送至服务端
     */
    Utils.initScheduleDataCache(this);
  }
  getAppSpecification() {
    return Utils.getAppSpecification(this.appType);
  }
  getDatasheetFields() {
    const promise = Utils.getDatasheetFields(this.datasheetId);
    // Schedule 类继承了 Observer，因此可使用 emit 方法触发相关事件
    // 以便相通知关组件更新必要的 model，例如 ./type-data/SyncMode 组件
    promise.then(data => this.emit("change:datasheet.fields", data));
    return promise;
  }
  getAppSchema() {
    const { appType, appConfig } = this;
    return Utils.getAppSchema({ appType, appConfig });
  }
}
