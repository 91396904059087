<template>
  <a-form-item>
    <div
      style="margin-bottom: 1.6em; display: flex; justify-content: space-between; align-items: center"
    >
      <span>字段映射</span>
      <a-button
        icon="reload"
        type="primary"
        size="small"
        :loading="loading"
        @click="getSchemas(true)"
      >
        重新加载
      </a-button>
    </div>
    <a-skeleton v-if="loading" active />
    <template v-else>
      <Field
        v-for="(item, index) in syncConfig.syncFields"
        :key="index"
        :source-fields="sourceFields"
        :destination-fields="destinationFields"
        :field="item"
        @remove="onRemove(index)"
      />
      <a-button type="primary" icon="plus" @click="onAdd()">
        添加一行
      </a-button>
    </template>
  </a-form-item>
</template>

<script>
import Field from "./SyncField";
import * as Utils from "../models/utils";

export default {
  components: { Field },
  props: {
    schedule: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      sourceFields: [],
      destinationFields: [],
      loading: false
    };
  },
  computed: {
    syncConfig() {
      return this.schedule.syncConfig || { syncFields: [] };
    }
  },
  created() {
    this.getSchemas();
  },
  methods: {
    async getSchemas(refresh = false) {
      this.loading = true;
      /**
       * @type {import("../editor/model").default}
       */
      const schedule = this.schedule;
      if (refresh) {
        Utils.clearCache();
      }
      try {
        const result = await Promise.all([
          schedule.getDatasheetFields(),
          schedule.getAppSchema()
        ]);
        this.sourceFields = result[0];
        this.destinationFields = result[1];
        // 仅在重新加载或 syncFields 为空时，才自动生成 syncFields
        if (!refresh && this.syncConfig.syncFields.length) {
          return;
        }
        const sourceMap = result[0].reduce((map, item) => {
          map[item.columnName] = item;
          return map;
        }, {});
        this.syncConfig.syncFields = result[1].map(item => {
          const sourceField = sourceMap[item.columnName];
          return {
            columnName: sourceField ? sourceField.columnName : void 0,
            columnType: sourceField ? sourceField.columnType : void 0,
            columnId: sourceField ? sourceField.columnId : void 0,
            fieldName: sourceField ? sourceField.fieldName : void 0,
            fieldType: sourceField ? sourceField.fieldType : void 0,
            destinationName: item.columnName,
            destinationType: item.columnType
          };
        });
      } catch (err) {
        this.$message.error("加载失败");
      } finally {
        this.loading = false;
      }
    },
    onAdd() {
      // <a-select /> 组件 model 为 null 时不显示 placeholder，故默认值为 undefined
      this.syncConfig.syncFields.push({ source: void 0, destination: void 0 });
    },
    onRemove(index) {
      this.syncConfig.syncFields.splice(index, 1);
    }
  }
};
</script>
