import { AppendPositionOptions, Oauth2TypeOptions } from "./models/constant";
import RequestAddress from "./RequestAddress";
import RequestParams from "./RequestParams";

const propsAuth = {
  config: Object
};

const propsInput = {
  label: String,
  value: String,
  placeholder: String
};

const Input = {
  props: propsInput,
  render() {
    return (
      <a-form-item label={this.label} help={this.$slots.default}>
        <a-input
          value={this.value}
          onInput={evt => this.$emit("input", evt.target.value)}
          placeholder={this.placeholder}
        />
      </a-form-item>
    );
  }
};

const InputPassword = {
  props: propsInput,
  render() {
    return (
      <a-form-item label={this.label}>
        <a-input-password
          value={this.value}
          onInput={evt => this.$emit("input", evt.target.value)}
          placeholder={this.placeholder}
          visibility-toggle
        />
      </a-form-item>
    );
  }
};

const Position = {
  props: propsAuth,
  render() {
    const config = this.config;
    const Options = AppendPositionOptions.map(item => (
      <a-radio value={item.value} key={item.value}>
        {item.key}
      </a-radio>
    ));
    return (
      <a-form-item label="追加到" help="参数以什么方式追加到请求中">
        <a-radio-group
          name="append-position"
          value={config.path}
          onInput={evt => (config.path = evt)}
        >
          {Options}
        </a-radio-group>
      </a-form-item>
    );
  }
};

export const BasicAuth = {
  props: propsAuth,
  render() {
    const config = this.config;
    return (
      <div>
        <Input
          label="用户名"
          placeholder="用户名"
          value={config.username}
          onInput={evt => (config.username = evt)}
        />
        <InputPassword
          label="密码"
          placeholder="密码"
          value={config.password}
          onInput={evt => (config.password = evt)}
        />
      </div>
    );
  }
};

export const BearerToken = {
  props: propsAuth,
  render() {
    const config = this.config;
    return (
      <div>
        <Input
          label="Token"
          placeholder="token"
          value={config.value}
          onInput={evt => (config.value = evt)}
        />
        <Input
          label="前缀"
          placeholder="前缀"
          value={config.tokenPrefix}
          onInput={evt => (config.tokenPrefix = evt)}
        />
        <Position config={config} />
      </div>
    );
  }
};

export const ApiKey = {
  props: propsAuth,
  render() {
    const config = this.config;
    return (
      <div>
        <Input
          label="键"
          placeholder="键"
          value={config.key}
          onInput={evt => (config.key = evt)}
        />
        <Input
          label="值"
          placeholder="值"
          value={config.value}
          onInput={evt => (config.value = evt)}
        />
        <Position config={config} />
      </div>
    );
  }
};

export const Oauth2P = {
  props: propsAuth,
  render() {
    const config = this.config;
    const Options = Oauth2TypeOptions.map(item => (
      <a-select-option key={item.key} value={item.value}>
        {item.key}
      </a-select-option>
    ));
    return (
      <div>
        <a-form-item label="AccessToken">
          <a-input
            placeholder="access token"
            value={config.value}
            onInput={evt => (config.value = evt.target.value)}
          >
            <a-select
              slot="addonBefore"
              value={config.accessTokenType}
              onChange={evt => (config.accessTokenType = evt)}
              style="width: 80px"
            >
              {Options}
            </a-select>
          </a-input>
        </a-form-item>
        <Input
          label="AccessToken 参数名"
          placeholder="参数名"
          value={config.key}
          onInput={evt => (config.key = evt)}
        />
        <Position config={config} />
      </div>
    );
  }
};

export const Oauth2T = {
  props: propsAuth,
  render() {
    const config = this.config;
    return (
      <div>
        <Oauth2P config={config} />
        <RequestAddress
          label="Toekn 请求地址"
          config={config}
          required={false}
        />
        <RequestParams label="Toekn 请求 Header" params={config.header} />
        <RequestParams label="Toekn 请求 Query" params={config.query} />
        <Input
          label="Token Response Key"
          placeholder="token response key"
          value={config.valueName}
          onInput={evt => (config.valueName = evt)}
        >
          {`Access Token 在 "获取 Token 接口" 的响应结果中，所在的字段名。
          比如接口返回结果为 "{ code: 0,msg: "", data: { access_token: "xxx" } }"，
          则 Token Response Key 为 "data.access_token"`}
        </Input>
      </div>
    );
  }
};
